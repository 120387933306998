import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Contacts.module.css'
import classNames from 'classnames'
import nstyles from '../Events/IndexNew.module.css'


export default function Contacts () {
  const cancelToken = useRef(axios.CancelToken.source())
  // const cancelToken = axios.CancelToken.source()

  const [rumcs, setRumcs] = useState();
  const [toggleQuestions, settoggleQuestions] = useState(false);
  const [search, SetSearch] = useState('');


  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('contacts_path', {}, true), { cancelToken: cancelToken.current.token })

      setRumcs(data.rumcs)
    }

    _fetch();

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [])

  const handleSearch = async (_search) => {
    const { data } = await axios.get(path('contacts_path', {}, true), { cancelToken: cancelToken.current.token, params: { search: _search } });
    setRumcs(data.rumcs);
  }

  const changeInput = (event) => {
    SetSearch(event.target.value);
    if (event.target.value.length >= 3 || event.target.value.length == 0) {
      handleSearch(event.target.value);
    }
  }

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  }

  return (
    <div className={page.root}>
      <div className={page.container}>
        <div className={page.title}>
          <h1>{I18n.t('pages.contacts.title')}</h1>

          <Helmet>
            <title>{I18n.t('pages.contacts.title')}</title>
          </Helmet>
        </div>

        <div className={page.common}>
          <p>Часто задаваемые вопросы:</p>
          <ul>
            <li>  
              <a href="/uploads/document/press_reliz/call-center-answers-2024.pdf"  target="_blank" rel="noopener noreferrer">
                Сборник ответов на наиболее распространенные вопросы по итогам работы «горячей линии» сети РУМЦ (2024)
              </a>
            </li>

            <li>
              <a onClick={() => (settoggleQuestions(!toggleQuestions))}>Ответы на самые часто задаваемые вопросы, связанные с получением второго высшего образования за счет бюджетных ассигнований, возникающие у лиц с инвалидностью</a>
              {toggleQuestions &&
                <ul>
                  <li><a href='https://ceph.miccedu.ru/incedu/news/16_11_2022/questions.pdf' target="_blank" rel="noopener noreferrer">Скачать в формате pdf</a></li>
                  <li><a href='https://ceph.miccedu.ru/incedu/news/16_11_2022/questions.docx' target="_blank" rel="noopener noreferrer">Скачать в формате docx</a></li>
                </ul>
              }
            </li>
          </ul>

          <p>
            Чтобы получить ответ на свой вопрос можно воспользоваться сервисом онлайн консультирования.
          </p>

          <p>
            <Link to="new_request_path" className={buttons.main}>Онлайн консультирование</Link>
          </p>

          <hr className={styles.hr} />

          <p>
            Для получения консультации по телефону вы можете позвонить в РУМЦ, закрепленный за территорией вашего проживания, с 9:00 до 17:00 (по местному времени) по указанным телефонам. Телефоны "горячей" линии работают по указанному графику по рабочим дням.
          </p>

          <div className={classNames(nstyles.flex_row)}>
            <div className={classNames(nstyles.flex_col, nstyles.title_col)}>
              <div className={classNames(nstyles.flex_row, nstyles.search_input_wrapper)}>
                <input 
                  className={nstyles.search_input} 
                  type="text" 
                  placeholder={"Поиск"} 
                  value={search}
                  onChange={changeInput}
                  onKeyPress={handleKeypress}
                />
                <div className={nstyles.new_search_button}><button onClick={() => (handleSearch())} type="submit" /></div>
              </div>
            </div>
          </div>

          {rumcs &&
            <>
              {rumcs.map(rumc =>
                <React.Fragment key={rumc.id}>
                  <div className={styles.rumc}>
                    <div className={styles.title_and_phone}>
                      <div className={styles.title}>
                        {rumc.title}
                      </div>
                      <div className={styles.phone}>
                        {rumc.phone.map((phone, i) =>
                          <div key={i}>
                            {phone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.regions}>
                      Закреплённая территория: {rumc.regions.length > 50 ? 'все регионы России' : rumc.regions.map(region => region.title).join(', ')}.
                    </div>

                    {rumc.id == 22 &&
                      <i className={styles.regions}>*Уточнение по графику работы "горячей" линии МГГЭУ: с пн по чт с 9:00 до 18:00, пт с 9:00 до 16:45 (по местному времени)</i>
                    }
                    {rumc.id == 21 &&
                      <i className={styles.regions}>*Уточнение по графику работы "горячей" линии Мининского университета: с пн по чт с 8:00 до 17:00, пт с 8:00 до 16:00 (по местному времени)</i>
                    }
                    {rumc.id == 19 &&
                      <i className={styles.regions}>*Уточнение по графику работы "горячей" линии РГСУ: с пн по пт с 9:00 до 18:00 (по местному времени)</i>
                    }
                    {rumc.id == 35 &&
                      <div className={styles.regions}>
                        Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минспорту России
                      </div>
                    }
                    {rumc.id == 36 &&
                      <div>
                        <div className={styles.regions}>
                          Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минздраву России
                        </div>

                        <i className={styles.regions}>*Уточнение по графику работы "горячей" линии РязГМУ им. И.П. Павлова: с пн по пт с 9:00 до 18:00 (по местному времени)</i>
                      </div>
                    }
                    {rumc.id == 34 &&
                      <div>
                        <div className={styles.regions}>
                          Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минкультуры России
                        </div>
    
                        <i className={styles.regions}>*Уточнение по графику работы "горячей" линии РГСАИ: с пн по пт с 9:00 до 18:00 (по местному времени)</i>
                      </div>
                    }
                    {rumc.id == 38 &&
                      <div>
                        <div className={styles.regions}>
                          Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минтрансу России
                        </div>

                        <i className={styles.regions}>*Уточнение по графику работы "горячей" линии РУТ: с пн по пт с 11:00 до 17:00 (по местному времени)</i>

                      </div>
                    }
                    {rumc.id == 17 &&
                      <div>
                        <div className={styles.regions}>
                          Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минпросвещения России
                        </div>

                        <i className={styles.regions}>*Уточнение по графику работы "горячей" линии МГППУ: с пн по чт с 9:00 до 18:00, пт с 9:00 до 16:45 (по местному времени)</i>

                      </div>
                    }
                    {rumc.id == 37 &&
                      <div className={styles.regions}>
                        Закреплённые вузы: отраслевые образовательные организации высшего образования, подведомственные Минсельхоза России
                      </div>
                    }
                  </div>
                  <hr className={styles.hr} />
                </React.Fragment>
              )}
            </>
          }
        </div>
      </div>
    </div>
  )
}
