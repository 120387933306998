import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import qs from 'query-string'
import axios from 'axios'
import I18n from '../I18n'
import Filters from '../Filters/Filters'
import Link, { path } from '../Routes'
import { Routes } from '../Routes'

import styles from '../Schools/Index.module.css'
import buttons from '../Buttons.module.css'
import page from '../Page.module.css'
import form from '../Schools/Form.module.css'

class Partners extends Component {

  state = {
    display: 50,
    input: '',
    toggle: {
      filters: false
    },
    partners: null,
    total: null,
  }

  loadMore = () => {
    this.setState({ display: (this.state.display + 50) })
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()
    const res = await axios.get(path('partners_show_path', {  }, true), { cancelToken: this._asyncRequest.token, params: qs.parse(this.props.location.search) })
    this._asyncRequest = null

    // this.setState({ total: res.data.partners.length })
    this.setState({ ...res.data, display: 50, total: res.data.partners.length })
  }

  filterSchools = (e) => {
    this.setState({ input: e.target.value.toLowerCase() })
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.partners === null) {
      this._loadAsyncData()
    }
  }

  static getDerivedStateFromProps (props, state) {
    if (state.filters && props.location.search !== state.search) {
      return {
        partners: null,
        search: props.location.search
      }
    }

    return null
  }

  render (){
    const { filters, toggle, total, input, display } = this.state
    const { history, location } = this.props
    let partners = this.state.partners

    if (partners) {
      partners = partners.filter(school => this.state.input === '' || school.name.toLowerCase().includes(this.state.input))
    }
    
    return (
        <div className={page.wrapper}>
            <div className={page.container}>

                <div className={classNames(page.title, page.tight)}>
                    <h1>
                        {I18n.t('partners.title')}
                    </h1>

                    <p>
                        Уважаемые посетители Портала! Информируем вас, что образовательные организации высшего образования, подведомственные Министерству просвещения Российской Федерации (педагогические вузы - <a target="_blank" rel="noopener noreferrer" href="https://edu.gov.ru/activity/main_activities/teacher_education/pedagogical_universities_list/">https://edu.gov.ru/activity/main_activities/teacher_education/pedagogical_universities_list/</a>), с сентября 2021 года осуществляют партнерское взаимодействие с отраслевым РУМЦ - с ФГБОУ ВО «Московский государственный психолого-педагогический университет». Актуализация информации по списку вузов-партнеров осуществляется в конце каждого квартала текущего года
                    </p>

                    <Helmet>
                        <title>{I18n.t('partners.title')}</title>
                    </Helmet>
                </div>

                <div className={styles.root}>
                    <div className={classNames(styles.search, styles.searchPartners, form.input)}>
                        <input type="text" value={input} onChange={this.filterSchools} placeholder={I18n.t('partners.search_input')} className={styles.partners_input} />
                    </div>

                    <div className={styles.toggle}>
                      <div className={styles.toggleSort}>
                        Сортировка по оценкам
                      </div>

                      <div className={styles.toggleFilters} onClick={() => this.setState(state => ({ toggle: { ...state.toggle, filters: !state.toggle.filters } }))}>
                        Фильтры
                      </div>
                    </div>

                    <div className={classNames(styles.filters, { [styles.active]: toggle.filters })}>
                      {filters &&
                        <Filters filters={filters} history={history} location={location} />
                      }
                      {/* <button onClick={() => this._loadAsyncData()}>SUBMIT</button> */}
                      </div>

                    {partners && partners.length > 0 &&
                      <div className={styles.schools}>
                        {partners.slice(0, this.state.display).map(partner =>
                          <a href={"//"+partner.site} key={partner.id} className={styles.school} target="_blank">
                            <div className={styles.title}>
                              {partner.name}
                            </div>
                            <div className={styles.link}>
                              {partner.site}
                            </div>
                            <div className={styles.meta}>
                              РУМЦ:&nbsp;<i>{partner.rumc}</i>&nbsp;| Субъект:&nbsp;<i>{partner.subject}</i>
                            </div>
                            {partner.note &&
                              <div className={styles.note}>
                                <div className={styles.title}>Примечание:</div>
                                <div className={styles.text}>{partner.note}</div>
                              </div>
                            }
                          </a>
                        )}
                      </div>
                    }
                </div>
                
                {partners && partners.length > 0 && total && total > display &&
                  <div className={styles.more} onClick={this.loadMore}>
                    <div className={buttons.black}>Показать ещё {total - display < 50 ? total - display : 50} из {total - display}</div>
                  </div>
                }

            </div>
        </div>
    )
  }
}

Partners.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object
}

class PartnersWrapper extends Component {
  render() {
    return (
      <Switch>
        <Route path={decodeURIComponent(Routes.partners_index_path)} component={Partners} exact strict />
      </Switch>
    )
  }
}

export default PartnersWrapper
