import React, { Component } from 'react'

import Link, { path } from '../Routes'

import styles from './Quick.module.css'

class Quick extends Component {
  render () {
    const { schools, umms, careers, courses, stories } = this.props
    
    return (
      <div className={styles.root}>
        <div className={styles.item}>
          <Link to="schools_path" params={{ hash: 'index' }} className={styles.link}>
            <div className={styles.title}>Поиск вуза</div>
            <div className={styles.count}>{schools.main}</div>
          </Link>
        </div>

        <div className={styles.item}>
          <Link to="umms_path" className={styles.link}>
            <div className={styles.title}>Учебные материалы</div>
            <div className={styles.count}>{umms}</div>
          </Link>
        </div>

        <div className={styles.item}>
          <Link to="careers_path" className={styles.link}>
            <div className={styles.title}>Атлас профессий</div>
            <div className={styles.count}>{careers}</div>
          </Link>
        </div>

        <div className={styles.item}>
          <Link to="courses_path" className={styles.link}>
            <div className={styles.title}>Онлайн-курсы</div>
            <div className={styles.count}>{courses}</div>
          </Link>
        </div>

        <div className={styles.item}>
          <Link to="abiturs_stories_path" className={styles.link}>
            <div className={styles.title}>Истории успеха</div>
            <div className={styles.count}>{stories}</div>
          </Link>
        </div>

        <div className={styles.item}>
          <a href={'/uploads/document/press_reliz/call-center-answers.pdf'} target="_blank" className={styles.link}>
            Популярные вопросы
          </a>
        </div>
      </div>
    )
  }
}

export default Quick
