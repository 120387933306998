import React, { useState, useEffect }  from 'react'
import axios from 'axios'
import classNames from 'classnames'
import { Helmet } from 'react-helmet-async'

import Link, { path } from '../Routes'
import I18n from '../I18n'
import Loaf from '../Loaf/Loaf'

import Covid from '../Requests/Covid'
import Events from '../Events/Block'
import Annotations from './Annotations'

import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import styles from './Index.module.css'

import UmmsIll from '../../images/pages/students/umms.svg'
import EmploymentIll from '../../images/pages/students/employment.svg'
import ProfDev from '../../images/pages/students/prof_dev_new.svg'


class StudentsIndex extends React.Component {
  state = {
    events: null,
  }

  componentDidMount () {
    this._loadAsyncData()
  }

  componentWillUnmount () {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source();

    const res = await axios.get(path('students_path', {}, true), { cancelToken: this._asyncRequest.token });
    this.setState({ ...res.data });

    this._asyncRequest = null;
  }
  
  render () {
    const { events } = this.state

    return (
      <div className={page.wrapper}>
        <div className={page.container}>
          <Loaf>
            <Link to="root_path">{I18n.t('nav.root')}</Link>
          </Loaf>
          <div className={page.title}>
            <h1>
              {I18n.t('nav.students')}
            </h1>

            <Helmet>
              <title>{I18n.t('nav.students')}</title>
            </Helmet>
          </div>

          <div className={styles.main}>
            <section className={classNames(styles.section, styles.employment)}>
              <div className={styles.ill}>
                <img src={EmploymentIll} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.students.employment.title')}
                </h2>

                <p>
                  {I18n.t('pages.students.employment.desc')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="careers_path" className={buttons.main}>
                      Подбор профессии
                    </Link>
                  </p>
                }
              </div>
            </section>

            <section className={classNames(styles.section, styles.umms)}>
              <div className={styles.ill}>
                <img src={UmmsIll} />
              </div>

              <div className={styles.content}>
                <h2>
                  {I18n.t('pages.students.umms.title')}
                </h2>

                <p>
                  {I18n.t('pages.students.umms.courses')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="courses_path" className={buttons.main}>
                      {I18n.t('courses.title')}
                    </Link>
                  </p>
                }

                <p>
                  {I18n.t('pages.students.umms.modules')}
                </p>

                {I18n.locale === 'ru' &&
                  <p>
                    <Link to="umms_path" className={buttons.main}>
                      Адаптационные модули
                    </Link>
                  </p>
                }
              </div>
            </section>

            <section className={classNames(styles.section, styles.prof_dev_section)}>
              <img src={ProfDev} className={styles.ProfDevImg} />

              <div>
                <h2>
                  Повышение квалификации для выпускников вузов
                </h2>

                <p>
                  Пройдите актуальные программы обучения для освоения новых навыков и компетенций 
                </p>
              </div>
            </section>
              
            <div className={styles.annotation_container}>
              <Annotations />
            </div>

            {I18n.locale === 'ru' && events &&
              <section className={classNames(styles.section, styles.events)}>
                <Events events={events} count={3} />
              </section>
            }

            <section className={classNames(styles.section, styles.request)}>
              <Covid />
              {/* <RequestNewBlock text={'С 20 июня работает «горячая линия» сети ресурсных учебно-методических центров по обучению лиц с инвалидностью по вопросам обучения в вузах'} title={'Горячая линия по вопросам обучения в вузах'} /> */}
            </section>

          </div>
        </div>
      </div>
    )
  }
}

export default StudentsIndex
