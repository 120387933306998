import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import axios from 'axios'
import { Helmet } from 'react-helmet-async'

import Datec from '../Datec'
import Redraft from '../Redraft'

import Loaf from '../Loaf/Loaf'
import Link, { path } from '../Routes'
import I18n from '../I18n'

import Report from './Show/Report'

import styles from './Show.module.css'
import nstyles from './IndexNew.module.css'
import page from '../Page.module.css'
import buttons from '../Buttons.module.css'
import { useHistory } from "react-router-dom";
import classNames from 'classnames'


export default function Show () {
  const [toggleAuditorium, setToggleAuditorium] = useState(false);
  const [toggleRegions, setToggleRegions] = useState(false);

  const [showModal, toggleShowModal] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [wrongInput, setwrongInput] = useState('');


  const cancelToken = useRef(axios.CancelToken.source())
  const { id } = useParams()

  const [event, setEvent] = useState({})

  let history = useHistory();

  useEffect(() => {
    const _fetch = async () => {
      const { data } = await axios.get(path('event_path', { id }, true), { cancelToken: cancelToken.current.token })
      setEvent(data.event)
      if (data.event.regions.length < 8) {
        setToggleRegions(true)
      }
      if (data.event.audience.length < 4) {
        setToggleAuditorium(true)
      }
      // console.log(data.event)
    }

    _fetch()

    return function cleanup () {
      cancelToken.current.cancel()
    }
  }, [id])

  const onChangeEmail = (event) => {
    const value = event.target.value;
    if (value.length < 100) {
      setInputEmail(value);
    }
  }; 


  const applyEmail = async () => {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    console.log(inputEmail)
    if (inputEmail) {
      if (!emailRegexp.test(inputEmail)) {
        setwrongInput('Неверный формат электроной почты!')
      } else {
        await axios.post(path('subscribe_event_path', { id }, true), { email: inputEmail, cancelToken: cancelToken.current.token })
        toggleShowModal(false);
      }
    } else {
      setwrongInput('Укажите электронную почту!')
    }
  }


  return (
    <div className={page.wrapper}>
      <div className={page.container}>
        {/* <Loaf>
          <Link to="events_path">{I18n.t('nav.events')}</Link>
        </Loaf> */}
        <Loaf>
          <div className={page.back} onClick={() => history.goBack()}>Назад</div>
        </Loaf>

        {event &&
          <>
            <div className={page.title}>
              <h1>
                {event.title}
              </h1>

              <Helmet>
                <title>{event.title}</title>
              </Helmet>

              {event.can_edit &&
                <p className={page.links}>
                  <Link to="account_event_path" params={{ id: event.id }}>Управление событием и участниками</Link>
                </p>
              }
              
              {event.id && event.role && event.role.assignable_type == "Rumc" && event.role.assignable_id != event.rumc.id &&
                <Link to="event_event_registrations_path" params={{ event_id: event.id, query: { limited: true } }} >Список участников события, связанных с вашим РУМЦ</Link>
              }
              
            </div>
            
            <div className={classNames(nstyles.tab, nstyles.inline_tab)}>
              {I18n.t(`meta.event.${event.section}`)}
            </div>

            
            <div className={styles.details_container}>
              <div className={nstyles.flex_row_align_center}>
                <div className={classNames(styles.details_container_col, nstyles.flex_row)}>
                  <div className={styles.col_8}>
                    <div className={nstyles.flex_col}>
        
                      <div className={nstyles.flex_col}>
                        <div className={nstyles.subtitle}>Дата</div>
                        <div className={nstyles.right_text}><Datec time={event.started_at} /></div>
                      </div>

                      {event.place &&
                        <div className={nstyles.flex_col}>
                          <div className={nstyles.subtitle}>Место проведения</div>
                          <div className={nstyles.right_text}>{event.place}</div>
                        </div>
                      }

                      {/* {event.regions && event.regions.length &&
                        <div className={nstyles.flex_col}>
                          <div className={nstyles.subtitle}>Регионы</div>
                          <div className={nstyles.regions_wrapper}>
                            {event.regions.map((r) => (
                              <div key={r.id} className={classNames(nstyles.right_text, nstyles.region_item)}>{r.title}</div>
                            ))}
                          </div>
                        </div>
                      } */}
                      {event.regions && event.regions.length &&
                        <div className={nstyles.flex_col}>
                          <div className={classNames(nstyles.subtitle, nstyles.flex_row_align_center)}>
                            <div>Регионы</div>
                            <div className={toggleRegions ? nstyles.fd_plus_active : nstyles.fd_plus} onClick={() => {setToggleRegions(!toggleRegions)}} />
                          </div>
                          {toggleRegions &&
                            <div className={nstyles.regions_wrapper}>
                              {event.regions.map((r) => (
                                <div key={r.id} className={classNames(nstyles.right_text, nstyles.region_item)}>{r.title}</div>
                              ))}
                            </div>
                          }
                        </div>
                      }

                      {event.rumc &&
                        <div className={nstyles.flex_col}>
                          <div className={nstyles.subtitle}>Организатор</div>
                          <Link to="rumc_path" params={{ id: event.rumc.id }} className={nstyles.right_text} key={event.rumc.id}>{event.rumc.title}</Link>
                        </div>
                      }

                    </div>
                  </div>
                  <div className={styles.col_4}>
                    {event.audience && event.audience.length &&
                      <div className={nstyles.flex_col}>
                        <div className={classNames(nstyles.subtitle, nstyles.flex_row_align_center)}>
                          <div>Аудитория</div>
                          <div className={toggleAuditorium ? nstyles.fd_plus_active : nstyles.fd_plus} onClick={() => {setToggleAuditorium(!toggleAuditorium)}} />
                        </div>
                        
                        {toggleAuditorium && 
                          <div className={nstyles.auditorium_tabs_wrapper}>
                            {event.audience.map((a, index) => (
                              <div key={index} className={nstyles.tab}>{a.title}</div>
                            ))}
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
                <div>
                  <div className={styles.show_image} />
                </div>
              </div>

            </div>
            {!event.in_archive &&
              <div className={styles.like_button} onClick={() => (toggleShowModal(!showModal))}>
                <div className={nstyles.flex_row_align_center}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#FFDD09" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 8V16" stroke="#FFDD09" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 12H16" stroke="#FFDD09" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <div>Мне интересно</div> 
                </div>
              </div>
            }

            {showModal &&
              <div className={styles.like_modal_container}>
                <div className={styles.like_modal}>
                  <div className={styles.close_button}>
                    <svg onClick={() => (toggleShowModal(!showModal))} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M8.3756 10L0 18.3756L1.6244 20L10 11.6244L18.3756 20L20 18.3756L11.6244 10L20 1.6244L18.3756 0L10 8.3756L1.6244 0L0 1.6244L8.3756 10Z" fill="#212121"/>
                    </svg>
                  </div>
                  
                  <div className={styles.modal_body}>
                    <p>Оставьте свою электронную почту. За несколько дней до начала события Вам придет напоминание.</p>
                    
                    {wrongInput && <p className={styles.modalErr}>{wrongInput}</p>}
                    <div className={classNames(nstyles.flex_row, nstyles.search_input_wrapper)}>
                      <input className={wrongInput ? classNames(nstyles.search_input, nstyles.empty) : nstyles.search_input} type="text" placeholder={"example@example.ru"} value={inputEmail} onChange={onChangeEmail} />
                      <div className={nstyles.new_search_button}><button onClick={applyEmail} /></div>
                    </div>
                    <p className={styles.modalNote}>*Для гарантированной доставки писем мы рекомендуем пользоваться глобальными почтовыми сервисами: @yandex, @mail, @gmail и т.п.</p>
                  </div>
                  <div className={styles.close_button}>
                    <svg width="251" height="125" viewBox="0 0 251 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100.3 70.5845V48.5166H248.748V70.5845H100.3Z" fill="#FFDD09"/>
                      <path d="M100.3 119.234V70.083H248.248V119.234H100.3Z" fill="white"/>
                      <rect x="100.288" y="49.0391" width="148.235" height="70.3775" stroke="#555555"/>
                      <rect x="114.649" y="82.0312" width="14.1415" height="14.7067" fill="#D9D9D9" stroke="#555555"/>
                      <rect x="145.683" y="54.8984" width="44.0767" height="8.33429" fill="#F2F2F2" stroke="#555555"/>
                      <rect x="240.158" y="43.5" width="10.3421" height="10.3979" fill="#D9D9D9" stroke="#555555"/>
                      <rect x="240.158" y="114.103" width="10.3421" height="10.3979" fill="#D9D9D9" stroke="#555555"/>
                      <rect x="137.514" y="82.0312" width="79.0981" height="22.3646" fill="#FFF19D" stroke="#555555"/>
                      <path d="M218.716 59.0656C218.716 61.3694 216.859 63.2327 214.572 63.2327C212.286 63.2327 210.428 61.3694 210.428 59.0656C210.428 56.7618 212.286 54.8984 214.572 54.8984C216.859 54.8984 218.716 56.7618 218.716 59.0656Z" fill="white" stroke="#555555"/>
                      <path d="M228.517 59.0656C228.517 61.3694 226.659 63.2327 224.373 63.2327C222.086 63.2327 220.229 61.3694 220.229 59.0656C220.229 56.7618 222.086 54.8984 224.373 54.8984C226.659 54.8984 228.517 56.7618 228.517 59.0656Z" fill="white" stroke="#555555"/>
                      <path d="M238.321 59.0656C238.321 61.3694 236.463 63.2327 234.176 63.2327C231.89 63.2327 230.032 61.3694 230.032 59.0656C230.032 56.7618 231.89 54.8984 234.176 54.8984C236.463 54.8984 238.321 56.7618 238.321 59.0656Z" fill="#FFF19D" stroke="#555555"/>
                      <path d="M108.492 70.4648H248.517" stroke="#555555" strokeLinecap="round"/>
                      <rect x="66.7803" y="59.0918" width="41.6165" height="40.2236" fill="#FFF19D" stroke="#555555"/>
                      <path d="M96.3115 43.5H106.654V53.8979H96.3115V43.5ZM96.3115 110.343H106.654V120.741H96.3115V110.343Z" fill="#D9D9D9" stroke="#555555"/>
                      <path d="M127.564 16.5454L133.679 1L166.13 13.5L160.486 28.5719C160.294 29.0858 159.723 29.3487 159.207 29.1609L128.152 17.851C127.624 17.6586 127.358 17.0686 127.564 16.5454Z" stroke="#555555"/>
                      <path d="M7.85133 105.058L5.67748 88.4942L40.1141 83.6811L42.4712 99.6031C42.5515 100.146 42.1799 100.653 41.6383 100.738L8.99821 105.915C8.44289 106.003 7.92455 105.616 7.85133 105.058Z" stroke="#555555"/>
                      <path d="M47.0266 44.3684L40.8154 36.8023L57.0969 23.1732L63.2013 30.3641C63.5563 30.7821 63.509 31.4081 63.0954 31.7675L48.454 44.4893C48.0293 44.8583 47.3839 44.8037 47.0266 44.3684Z" fill="#FFDD09" fillOpacity="0.2"/>
                      <path d="M40.8154 36.8023L47.0266 44.3684C47.3839 44.8037 48.0293 44.8583 48.454 44.4893L63.0954 31.7675C63.509 31.4081 63.5563 30.7821 63.2013 30.3641L57.0969 23.1732M40.8154 36.8023L57.0969 23.1732M40.8154 36.8023L51.7979 34.9287C52.1382 34.8706 52.4244 34.6408 52.5548 34.3208L57.0969 23.1732" stroke="#FFDD09" strokeWidth="0.610319"/>
                      <path d="M145.289 16.4932L134.677 2L164.669 13.482L146.278 16.8857C145.9 16.9556 145.516 16.803 145.289 16.4932Z" fill="#555555" stroke="#555555"/>
                      <path d="M23.331 96.4112L7.03516 88.8838L38.8265 84.3714L24.3852 96.2749C24.089 96.5191 23.6793 96.5721 23.331 96.4112Z" fill="#555555" stroke="#555555"/>
                    </svg>
                  </div>
                </div>
              </div>
            }

            <div className={styles.root}>
       
              <div className={styles.event}>
                {event.registrations && event.registrations.length > 0 && 
                  <div className={styles.registrations}>
                    <div className={styles.registrationsText}>
                      <p>
                        {event.registrations.length === 1 && 'Вы успешно зарегистрировались на данное событие. ' }
                        {event.registrations.length > 1 && `Вы успешно зарегистрировали ${I18n.t('event.registrations', { count: event.registrations.length })}. ` }
                        Вы можете добавить других участников которые придут с вами или внести правки.
                      </p>
                    </div>

                    <div className={styles.registrationsIndex}>
                      <Link to="event_event_registrations_path" params={{ event_id: event.id }} className={classNames(buttons.black, styles.a_in_btn_white)}>
                        Ваши участники
                      </Link>
                    </div>
                  </div>
                }

                {event.intro &&
                  <Redraft src={event.intro} />
                }

                {!event.intro && event.text &&
                  <ReactMarkdown source={event.text} />
                }

                {/* {event.attachments.length > 0 && */}
                {event.attachments &&
                  <div className={styles.files}>
                    {event.attachments.map(attachment =>
                      <a key={attachment.id} href={attachment.url} target='_blank'>{attachment.section ? I18n.t(`attachments.types.${attachment.section}`) : attachment.title}</a>
                    )}
                  </div>
                }

                {event.url && false &&
                  <div className={styles.url}>
                    <a className={buttons.main} target="_blank" rel="noopener noreferrer" href={event.url}>Подробнее о событии</a>
                  </div>
                }
                
                {event.registration &&
                  <div>
                    {event.registration.can_new &&
                      <div className={styles.registrationNew}>
                        <h2>
                          Стать участником события
                        </h2>
                        <p>
                          Регистрация доступна всем участникам. Информация поступит организаторам события, а после окончания вы сможете оценить его качество.
                        </p>
                        <p>
                          <Link to="new_event_event_registration_path" params={{ event_id: event.id }} className={classNames(buttons.black, styles.a_in_btn_white)}>
                            Зарегистрироваться
                          </Link>
                        </p>
                      </div>
                    }
                  </div>
                }

                {!event.future && event.report &&
                  <Report {...event.report} />
                }
              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}
